import { createRouter,createWebHistory } from 'vue-router'

const routes = [
  { 
    path: '/',
    component: ()=>import("../components/Index.vue")
  },

  { 
    path: '/user_order',
    component: ()=>import("../components/Order.vue")
  },
  { 
    path: '/order_evaluation', 
    component: ()=>import("../components/OrderEvaluation.vue")
  },

  { 
    path: '/teacher_register_37pG2LZnaE',
    component: ()=>import("../components/TeacherRegister.vue")
  },

  { 
    path: '/order_list_uEPus1kYb0',
    component: ()=>import("../components/OrderList.vue")
  },
  { 
    path: '/order_detail_CaQlPZqX3c',
    component: ()=>import("../components/OrderDetail.vue")
  },

  { 
    path: '/empty_page', 
    component: ()=>import("../components/Empty.vue")
  },
];
 
const router = createRouter({

  history: createWebHistory(),
  routes,
})
export default router
